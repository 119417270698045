body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
}

td, th {
  border: 1px solid black;
}

th {
  padding: 12px;
  background-color: rgb(0, 63, 134);
  color: white;
}

hr {
  width: 75%;
  margin-top: 30px;
  margin-bottom: 30px;
  color: white;
}

.modal {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid darkgray;
  background: white;
  overflow: auto;
  border-radius: 4px;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.modal-confirm-button {
  margin-right: 10px;
  margin-top: 10px;
  float: right;
}

.modal-cancel-button {
  margin-top: 10px;
  float: right;
}

.ReactModalPortal, #root {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
  padding-top: 30px;
  padding-bottom: 30px;
}

select {
  font-size: calc(2px + 2vmin);
}

.create-update-submit-input {
  font-size: calc(2px + 2vmin);
}

.error {
  font-size: calc(2px + 2vmin);
  color: red;
}

.spinner {
  margin-top: 5px;
  margin-right: auto;
  margin-left: auto;
  border: 8px solid lightgray;
  border-radius: 50%;
  border-top: 8px solid rgb(0, 63, 134);
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
