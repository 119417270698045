.red {
    background-color: red;
}

.yellow {
    background-color: yellow;
}

.green {
    background-color: greenyellow;
}

.modal {
    max-height: 50rem;
}
